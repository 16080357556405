<template>
  <div>
    <headerImg :src="img" height="640" />
    <div class="content">
      <div>
        <div>技术支持</div>
        <div>
          <i class="iconfont duozhi-icon-dianhua"></i>
          <a href="tel:400-9699-225">400-9699-225</a>
        </div>
        <div>感谢使用深圳多知能源物联有限公司产品 我们很高兴为您提供帮助</div>
      </div>
      <div>
        <div>投诉建议</div>
        <div>
          <i class="iconfont duozhi-icon-dianhua"></i>
          <a href="tel:400-9699-225">400-9699-225</a>
        </div>
        <div>真诚沟通 用心聆听 公司专注于您的每一份信任</div>
      </div>
    </div>
  </div>
</template>

<script>
import img from "@/assets/images/customerService/haederimg.png"
import headerImg from "@/components/headerImg.vue"
export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
      {
        name: "format-detection",
        content: "telephone=yes",
      },
    ],
  },
  components: {headerImg},
  data(){
    return {
      img
    }
  }

}
</script>

<style lang="scss" scoped>
.content{
  width: 1200px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin: 50px auto 500px auto;
  & > div{
    padding: 44px 48px;
    box-shadow: 0 0 20px rgb(0 0 0 / 5%);
    transition: all .5s;
    flex: 1;
    margin: 0 10px;
    & > div:nth-child(1){
      font-size: 20px;
      line-height: 1.2;
      color: #444;
      font-weight: 600;
      margin-bottom: 20px;
    }
    & > div:nth-child(2), & > div:nth-child(3){
      font-size: 14px;
      margin: 10px 0;
      color: #535353;
      transition: all .2s;
      a{
        margin-left: 10px;
        color: #535353;
      }
    }
  }
  & > div:hover{
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    & > div:nth-child(1){
      color: #444;
    }
    div{
      color: #2da0db;
    }
  }
}

@media screen and (max-width: 600px) {
  .content{
    max-width: 98%!important;
    flex-direction: column;
  }
}
</style>