<template>
  <div class="header-img-box">
    <div class="header-img" :style="'height:'+height+'px'">
      <img :src="src" alt="">
    </div>
    <img class="header-img-center" :src="src" alt="">

  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ""
    },
    height: {
      type: [String, Number],
      default: 500
    }
  }
}
</script>

<style lang="scss" scoped>
.header-img-box{
  
}
.header-img{
  width: 100%;
  overflow: hidden;
  position: relative;
  img{
    width: 1920px;
    position: absolute;
    left: calc(50% - 960px);
    height: 100%;
  }
}
.header-img-center{
  display: none;
}
@media screen and (max-width: 1600px){
  .header-img{
    display: none;
  }
  .header-img-center{
    display: block;
    width: 100%;
  }
}

</style>